import { SubscriptionFeature } from "../../types/billing/SubscriptionFeature";
import { RoleActionBlocker } from "./RoleActionBlocker";
import { ActionBlocker, TRenderChildren } from "./ActionBlocker";

interface Props {
    children: TRenderChildren;
    requiredFeatures: SubscriptionFeature[];
    requiredRoles: string[];
}

export function RoleAndFeatureBlocker({
    children,
    requiredFeatures,
    requiredRoles,
}: Props) {
    return (
        <RoleActionBlocker roles={requiredRoles}>
            {(blockedByRole) => (
                <ActionBlocker
                    overrideRequiredFeatures={blockedByRole}
                    features={requiredFeatures}
                >
                    {(blockedBySubscription) =>
                        children(blockedByRole || blockedBySubscription)
                    }
                </ActionBlocker>
            )}
        </RoleActionBlocker>
    );
}
